import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';
import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { MapContext } from '../MapContext'
import { useAppSelector } from '../../../../../state/hooks';
import { getWidth } from 'ol/extent';
import TileGrid from 'ol/tilegrid/TileGrid';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';

interface IProps {
    url?: string;
    layername: any;
    serviceId: number;
    tag: string;
    priority?: number;
    visibleDomains: [];
    cql_filter_?:string;
    layername_?:string
}

export const WmsLayer = (props: PropsWithChildren<IProps>) => {
    const map = useContext(MapContext)
    const t = useRef<ImageLayer<any> | null>(null);
    const [cqlFilter, setCqlFilter] = useState<any>([])
    const token = useAppSelector((state: any) => state.customize.token);

    const projExtent = (window as any).olMap?.getView().getProjection().getExtent('EPSG:3857');
    const resolutions = new Array(22);
    const startResolution = getWidth(projExtent) / 256;
    const layerFilterCql : any = useAppSelector(state => state.table.layerFilterCql);

    useEffect(() => {
        //for tile size
        for (let i = 0, ii = resolutions.length; i < ii; ++i) {
            resolutions[i] = startResolution / Math.pow(2, i);
        }
        const tileGrid = new TileGrid({
            //@ts-ignore
            extent: map?.getView().getProjection().getExtent(),
            resolutions: resolutions,
            //@ts-ignore
            tileSize: [512, 512],
        });

        // console.log("REFRESH cqlFilter => ",cqlFilter);


 //  return   { id:style.id, key: 'classified/' + fieldName + '/' + /* dom.id */ style.id + '/' + layer.name, label: /* dom.desc */classifiedValue, icon: `iconforsidebar_${layer.id}${style.id}` }
    /*  let classifiedValue;
        if(style.classified_value_type==="text"){
          classifiedValue=style.classified_text_value || 'Boş'; // ? null
        }else{
          classifiedValue=style.classified_numeric_value || 'Boş'; // ? null
        }
     */
     // graduated   return { id:style.id, key: 'graduated/' + fieldName + '/' + /* dom.id */ style.id + '/' + layer.name, label: /* dom.desc */graduatedVal, icon: `iconforsidebar_${layer.id}${style.id}` }
     //let graduatedVal = (style.graduated_min || 'Boş') + ' - ' + (style.graduated_max || 'Boş');
     //

        const tileLayer: any = new ImageLayer({
            source: new ImageWMS({
                params: {
                    layers: props.layername,
                    token: getToken(),
                    cql_filter: props.layername.map((layer:any)=>{
                        // console.log("cqlFilter :",cqlFilter);

                        const cqlFilterOfLayer :any = cqlFilter.filter((filter:any)=>filter.layername === layer)
                        //  console.log("cqlFilterOfLayer :",cqlFilterOfLayer);
                        // console.log("layer :",layer);
                        if(layerFilterCql.get(layer)){
                            const cql = layerFilterCql.get(layer).cql
                            if(cqlFilterOfLayer.length && Object.keys(cqlFilterOfLayer[0]).includes("domainId")){
                                const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ') 
                                //cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                            //    console.log(`(${cql}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)`);
                                
                                return `(${cql}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)`
                            }
                            else if(cqlFilterOfLayer.length && Object.keys(cqlFilterOfLayer[0]).includes("graduatedId")){
                            
                                const cqlFilterT = cqlFilterOfLayer.map((domain: any) => {
                                   return isNaN(domain.graduatedId.split(' - ')[0]) ? `(${domain.filterTag} is NULL)` :
                                    `(${domain.filterTag}>=${+domain.graduatedId.split(' - ')[0]} AND ${domain.filterTag}<${+domain.graduatedId.split(' - ')[1]})`
                                }).join(' OR ')
                               
                             //   console.log(`(${cql}) AND (` + cqlFilterT);
                                return `(${cql}) AND ` + cqlFilterT
                                // return `(${cql}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)`
                            }
                            else if(cqlFilterOfLayer.length && Object.keys(cqlFilterOfLayer[0]).includes("classifiedId")){

                                const cqlFilterT = cqlFilterOfLayer.map((domain: any) =>{
                                    return domain.classifiedId.includes('Boş') ? `(${domain.filterTag} is NULL)` : `${domain.filterTag}=${domain.classifiedId}`}).join(' OR ') 
                           
                            //    console.log(`(${cql}) AND (` + cqlFilterT);
                                return `(${cql}) AND ` + cqlFilterT
                                 // return `(${cql}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)`
                            }
                            else{
                                return cql
                            }
                        }else{
                            if(cqlFilterOfLayer.length && Object.keys(cqlFilterOfLayer[0]).includes("domainId")){
                                const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                                return cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                            }
                            else if(cqlFilterOfLayer.length && Object.keys(cqlFilterOfLayer[0]).includes("graduatedId")){
                                // console.log("domain.graduatedId.split(' - ') :",cqlFilterOfLayer);

                                const cqlFilterT = cqlFilterOfLayer.map((domain: any) =>{
                                    return isNaN(domain.graduatedId.split(' - ')[0]) ? `(${domain.filterTag} is NULL)` :
                                    `(${domain.filterTag}>=${+domain.graduatedId.split(' - ')[0]} AND ${domain.filterTag}<${+domain.graduatedId.split(' - ')[1]})`
                                }).join(' OR ')

                              //  const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `(${domain.filterTag}>=${+domain.graduatedId.split(' - ')[0]} AND ${domain.filterTag}<${+domain.graduatedId.split(' - ')[1]})`).join(' OR ')
                                return cqlFilterT
                            }
                            else if(cqlFilterOfLayer.length && Object.keys(cqlFilterOfLayer[0]).includes("classifiedId")){
                                //    const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${domain.classifiedId}`).join(' OR ') 
                                const cqlFilterT = cqlFilterOfLayer.map((domain: any) =>{
                                    return domain.classifiedId.includes('Boş') ? `(${domain.filterTag} is NULL)` : `${domain.filterTag}=${domain.classifiedId}`}).join(' OR ') 

                                return cqlFilterT
                            }
                            /* if(cqlFilterOfLayer.length){
                                const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                                return cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                            } */else{
                                return `1=1`
                            }
                        }
                   /*  if(cqlFilterOfLayer.length){
                        const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                        return props.cql_filter_ === '' ? cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL` : 
                        props.layername_ === layer ? `(${props.cql_filter_}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)` : cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                    }else{
                        return props.cql_filter_ === '' ? '1=1' :  props.layername_ === layer ? `${props.cql_filter_}` : '1=1'
                    } */
                    }).join(';')
                    // TILED: false
                    // `${cqlFilter && cqlFilter.length > 0 ? cqlFilter.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ') : `1=1`}`  // domaine göre filtrele
                },
                url: props.url ? props.url : "",
                crossOrigin: "anonymous",
                // tileGrid: tileGrid,
                serverType: 'geoserver',

            }),
            zIndex: props.priority ? Number.MAX_SAFE_INTEGER - props.priority : 1
        })
        t.current = tileLayer;
    }, [cqlFilter.length, props.layername.length, layerFilterCql]);

    //domaine ve layerleri eşleştirme
    useEffect(() => {
        const filterByLayer: any = []
        // console.log("props. visibleDomains :",props.visibleDomains);
        // console.log("props.layername :",props.layername);
        props.visibleDomains.map((domain: any) => {
            props.layername.map((layername: any) => {
                if (domain.layername === layername) {
                    filterByLayer.push(domain)
                }
            })
        })
        // console.log("filterByLayer : ",filterByLayer);

        setCqlFilter([...filterByLayer])
    }, [props.visibleDomains, props.layername])


    useEffect(() => {
        const tileLayer = t.current!;
        tileLayer.set('tag', props.tag);
        tileLayer.set('serviceId', props.serviceId);
        tileLayer.setVisible(props.layername)
        tileLayer.getVisible()
        map.addLayer(tileLayer);
        return () => { map.removeLayer(tileLayer) }
    }, [props.tag, props.url, cqlFilter,props.layername])


    useEffect(() => {
        const tileLayer = t.current!;
        tileLayer.setVisible(props.layername);
        tileLayer.getSource().updateParams({
            layers: props.layername
        })
    }, [props.layername, props.serviceId])

    const getToken = () => {
        return localStorage.getItem('token')
    }
    

    /**
     *  e.k. modified WmsLayer by adding useEffect
     */
    // useEffect(() => {
    //     const tileLayer: any = new Tile({
    //         source: new TileWMS({
    //             params: {
    //                 layers: props.layername,
    //                 token: token,
    //                 cql_filter: `${cqlFilter && cqlFilter.length > 0 ? cqlFilter.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ') : `1=1`}`  // domaine göre filtrele 
    //             },
    //             url: props.url
    //         }),
    //         zIndex: props.priority ? props.priority : 1
    //     })
    //     t.current = tileLayer;
    // }, []);

    // //domaine ve layerleri eşleştirme
    // useEffect(() => {
    //     const filterByLayer: any = []

    //     props.visibleDomains.map((domain: any) => {
    //         props.layername.map((layername: any) => {
    //             if (domain.layername === layername) {
    //                 filterByLayer.push(domain)
    //             }
    //         })
    //     })
    //     setCqlFilter(filterByLayer)
    // }, [props.visibleDomains, props.layername])


    // useEffect(() => {
    //     const tileLayer = t.current!;


    //     tileLayer.set('tag', props.tag);
    //     tileLayer.set('serviceId', props.serviceId);
    //     tileLayer.setVisible(props.layername)
    //     tileLayer.getVisible()
    //     map.addLayer(tileLayer);

    //     return () => { map.removeLayer(tileLayer) }
    // }, [props.tag, props.url, cqlFilter, props.layername])


    // useEffect(() => {
    //     const tileLayer = t.current!;
    //     tileLayer.setVisible(props.layername);
    //     tileLayer.getSource().updateParams({
    //         layers: props.layername
    //     })
    // }, [props.layername, props.serviceId])

    return null;
}
